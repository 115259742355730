import { Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router";

import LhumosSpaces from "../spaces.json"; // Spaces whitelist database
import logo from "../img/logo.png";

function Footer() {
  const location = useLocation();
  var match = matchPath({ path: "/spaces/:id" }, location.pathname);
  var SPACE_INDEX = match && match.params.id;
  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match && match.params.id;
  }

  // Default footer style (black with white text)
  let footerStyle = "bg-black text-white";

  return (
    <footer className={`${footerStyle} p-4`}>
      <div className="flex justify-between items-center">
        <div className="flex-none mr-8">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="logo" className="h-8" />
          </Link>
          <p className="text-xs">V alpha 0.8.5</p>
        </div>

        <div className="flex text-center  mx-auto">
          {SPACE_INDEX != null && LhumosSpaces[SPACE_INDEX] && (
            <p className="text-xs">
              {LhumosSpaces[SPACE_INDEX].footerText}
            </p>
          )}
        </div>

        {SPACE_INDEX != null && LhumosSpaces[SPACE_INDEX] && LhumosSpaces[SPACE_INDEX].footerImage && (
          <div className="flex-none ml-8">
            <img
              src={LhumosSpaces[SPACE_INDEX].footerImage}
              alt="logo"
              className="h-8"
            />
          </div>
        )}
      </div>
    </footer>
  );
}

export default Footer;
