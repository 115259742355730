import React from "react";
import { useParams, Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router";

import LhumosSpaces from "../spaces.json"; //spaces whitelist database

function Breadcrumbs(props) {
  const location = useLocation();
  var match = matchPath({ path: "/spaces/:id" }, location.pathname);
  var SPACE_INDEX = match && match.params.id;
  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match && match.params.id;
  }

  if (SPACE_INDEX == null){
    return (
        <div className="flex w-100% pl-24 pt-2 pb-2 text-lg backdrop-brightness-95">
          <Link to="/spaces" className="no-underline text-white pr-4 font-bold">
            Spaces
          </Link>
        </div>
      );
  }


  if (props.videoName != null){
    return (
      <div className="flex max-w-screen-2xl pl-24 pt-2 pb-2 text-lg">
        <Link to="/spaces" className="no-underline text-white pr-4">
          Spaces  ➔  
        </Link>
        <Link to={"/spaces/"+SPACE_INDEX} className="no-underline text-white pr-4">
        {LhumosSpaces[SPACE_INDEX].externalid}  ➔ 
        </Link>
        <p className="font-bold text-white">{props.videoName}</p>
        
      </div>
    );
  }


  if (SPACE_INDEX != null){
    return (
      <div className="flex max-w-screen-2xl pl-24 pt-2 pb-2 text-lg">
        <Link to="/spaces" className="no-underline text-white pr-4">
          Spaces  ➔ 
        </Link>
        <Link to={"/spaces/"+SPACE_INDEX} className="no-underline text-white pr-4 font-bold">
        {LhumosSpaces[SPACE_INDEX].externalid}
        </Link>
      </div>
    );
  }

}

export default Breadcrumbs;
