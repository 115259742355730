//GET
export async function ApiGet(url) {
  var verbose = false; //EDIT THIS TO GET BETTER DEBUG INFO
  if (verbose) {
    console.log("1 - trying to call api on url: " + url);
  }
  const resp = await fetch(url);
  const json = await resp.json();
  localStorage.setItem(url, JSON.stringify({ data: json }));
  if (verbose) {
    console.log("%c2 - ↓↓↓ SUCCESS fetched ↓↓↓", "color: #bada55");
    console.log(url);
    console.log(json);
  }
  return json;
}

export default ApiGet;
