import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import Footer from "./components/Footer";
import "react-toastify/dist/ReactToastify.css";
import CollectionContainer from "./components/CollectionContainer";
import Breadcrumbs from "./components/Breadcrumbs";
import LhumosSpaces from "./spaces.json";
import LoadingScreen from "./components/LoadingScreen";
import ApiGet from "./components/APImodules.js";
import web from "./img/web.png";

function SingleSpace() {
  const [isLoading, setIsLoading] = useState(true);
  const [space, setSpace] = useState(null);
  const [collections, setCollections] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [isFilterHideEmpty, setIsFilterHideEmpty] = useState(false);
  const [expandedCollectionId, setExpandedCollectionId] = useState(null);

  const { spaceId } = useParams();
  const SPACE_INDEX = spaceId;
  const SPACE_ID = LhumosSpaces[SPACE_INDEX]?.id;

  const spaceDetails = useMemo(() => LhumosSpaces[SPACE_INDEX], [SPACE_INDEX]);

  const toggleFilterHideEmpty = () => {
    setIsFilterHideEmpty(!isFilterHideEmpty);
  };

  const reorderCollections = (collections, order) => {
    const orderedCollections = order.map(id => collections.find(collection => collection.id === id)).filter(Boolean);
    const remainingCollections = collections.filter(collection => !order.includes(collection.id));
    return [...orderedCollections, ...remainingCollections];
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const fetchedSpace = await ApiGet(`https://clowder.eessi.science/api/spaces/${SPACE_ID}`);
      setSpace(fetchedSpace);

      const fetchedCollections = await ApiGet(`https://clowder.eessi.science/api/spaces/${SPACE_ID}/collections`);
      const collectionOrder = spaceDetails.collectionOrder || [];
      const orderedCollections = reorderCollections(fetchedCollections, collectionOrder);

      setCollections(isFilterHideEmpty ? orderedCollections.filter(collection => collection.thumbnail != null) : orderedCollections);
      setDatasets(await ApiGet(`https://clowder.eessi.science/api/spaces/${SPACE_ID}/datasets`));
      setIsLoading(false);
    };

    if (SPACE_ID) {
      fetchData();
      window.scrollTo(0, 0);
    }
  }, [isFilterHideEmpty, SPACE_ID, spaceDetails]);

  if (isLoading || !spaceDetails) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen relative overflow-hidden" style={{ backgroundImage: `linear-gradient(to bottom, ${spaceDetails.color1}, #000000)` }}>
      <Breadcrumbs />
      <div className="bg-cover bg-center bg-fixed bg-no-repeat p-8 md:p-24 text-white relative" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${spaceDetails.bgurl})` }}>
        <div className="max-w-screen-xl mx-auto h-fit rounded-3xl md:p-8 backdrop-blur-2xl relative">
          <a href={spaceDetails.website} target="_blank" rel="noopener noreferrer">
            <img className="w-10 h-10 absolute right-8 top-8" src={web} alt="website" />
          </a>
          <div className="flex flex-col md:flex-row items-center">
            <img className="w-24 h-24 md:w-40 md:h-40 min-w-[6em] md:min-w-[10em] rounded-xl border-4" src={spaceDetails.iconurl} alt="cecam logo" style={{ borderColor: spaceDetails.accent }} />
            <div className="md:ml-4 mt-4 md:mt-0 p-4">
              <h1 className="text-xl md:text-4xl font-bold">{space?.name}</h1>
              <div className="my-2">
                <p className="bg-transparent text-xs md:text-base">{space?.description?.substring(0, 600)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-gray-900 text-2xl max-w-screen-xl m-auto mt-4 rounded-3xl" style={{ backgroundImage: `linear-gradient(to bottom, ${spaceDetails.color2}, rgba(0, 0, 0, 0))` }}>
        <div className="max-w-screen-xl m-auto text-white">
          <div className="flex flex-wrap">
            {collections.map((collection, idx) => (
              <CollectionContainer
                key={collection.id}
                name={collection.name}
                spaceIndex={SPACE_INDEX}
                index={idx}
                isExpanded={expandedCollectionId === collection.id}
                onToggleExpand={() => setExpandedCollectionId(expandedCollectionId === collection.id ? null : collection.id)}
                isTopLevel={true}
              >
                {collection}
              </CollectionContainer>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SingleSpace;
