import React from "react";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Slider from "react-slick";

import ApiGet from "./APImodules.js";
import Backdrop from "./Backdrop";
import LoadingCircle from "./LoadingCircle.js";

//ICONS
import pdf from "../img/filetypes/pdf.png";
import download from "../img/download-arrow.png";

function PdfContainer(props) {
  const FILE_ID = props.children.id;

  //STATE
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [isImageView, setIsImageView] = useState(true);
  const [pdfMetadata, setpdfMetadata] = useState(true);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [previewArray, setPreviewArray] = useState([]);

  function openPdfViewer() {
    setPdfViewerOpen(true);
  }
  function closePdfViewer() {
    setPdfViewerOpen(false);
  }
  function nextPage() {
    if (pageNumber < pdfMetadata[0].content.num_pages)
      setpageNumber(pageNumber + 1);
    else setpageNumber(1);
    changeSelectedSlidePage(pageNumber);
  }
  function previousPage() {
    if (pageNumber == 1) setpageNumber(pdfMetadata[0].content.num_pages);
    else setpageNumber(pageNumber - 1);
    changeSelectedSlidePage(pageNumber);
  }

  function changeView() {
    if (isImageView) setIsImageView(false);
    else setIsImageView(true);
  }

  //API CALLS
  useEffect(() => {
    setIsLoading(true);
    GetDatasets();
  }, []);

  async function GetDatasets() {
    const result = await ApiGet(
      `https://clowder.eessi.science/api/files/${FILE_ID}/metadata.jsonld`
    );
    setpdfMetadata(result);

    // Map through the preview images and set the correct URLs in previewArray
    const newArray = result[0].content.preview_images.map(
      (image) => `https://clowder.eessi.science/api/previews/${image}`
    );
    setPreviewArray(newArray);

    setIsLoading(false);
  }

  //slider carousel variables
  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderSettings = {
    infinite: false,
    vertical: true,
    lazyLoad: false,
    speed: 300,
    slidesToShow: 4,
    focusOnSelect: true,
    dots: false,
    centerPadding: 1,
    centerMode: true,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  if (isLoading) {
    return <LoadingCircle />;
  }

  if (pdfViewerOpen) {
    document.body.style.overflow = "hidden";
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.body.style.overflow = "visible";
  }

  function jumpToPage(pageNumber) {
    setpageNumber(pageNumber);
  }
  function changeSelectedSlidePage(index) {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  }

  return (
    <div
      className="bg-red-300 text-white py-2 px-4 rounded mt-4 cursor-pointer"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url("https://clowder.eessi.science/api/previews/${pdfMetadata[0].content.preview_images[0]}")`,
      }}
    >
      {pdfViewerOpen ? (
        <div
          className="flex absolute z-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
        "
        >
          <div className="mr-8 w-32">
            <Slider {...sliderSettings} ref={sliderRef}>
              {/* Render the Slider using previewArray */}
              {previewArray.map((url, idx) => (
                <div
                  key={idx}
                  onClick={() => jumpToPage(idx)}
                  className={
                    idx === slideIndex
                      ? "w-8 rounded-xl border-4 border-red-600 outline-none cursor-pointer transition delay-100 ease-in-out hover:-translate-y-1 hover:scale-95"
                      : "w-8 rounded-xl cursor-pointer transition delay-100 ease-in-out hover:-translate-y-1 hover:scale-95"
                  }
                >
                  <div className="text-white rounded-lg w-10 h-10 flex justify-center items-center font-semibold p-3 absolute">
                    {idx + 1}
                  </div>
                  <img
                    src={url}
                    alt={"img number" + idx}
                    className="rounded-xl"
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="text-center">
            <div onClick={() => changeView()} className="p-4">
            {/* change view type, locked for webinar */} 
            </div>
            {isImageView ? (
              <div
                className="w-[40rem] h-[32rem] bg-contain bg-no-repeat"
                style={{
                  backgroundImage: `url("https://clowder.eessi.science/api/previews/${pdfMetadata[0].content.preview_images[pageNumber]}")`,
                }}
              ></div>
            ) : (
              <div>
                {isPdfLoading ? <LoadingCircle /> : false}
                <Document
                  file={`https://clowder.eessi.science/api/files/${props.children.id}/blob/`}
                  onClick={nextPage}
                  onLoadSuccess={() => setIsPdfLoading(false)}
                >
                  <Page pageNumber={pageNumber} className="rounded w-96" />
                </Document>
              </div>
            )}
            <button
              className=" text-white font-bold py-2 px-4"
              onClick={previousPage}
            >
              <p className="text-4xl transition delay-100 ease-in-out hover:-translate-x-4 hover:scale-95">
                ←
              </p>
            </button>
            {pageNumber}/{pdfMetadata[0].content.num_pages}
            <button
              className=" text-white font-bold py-2 px-4"
              onClick={nextPage}
            >
              <p className="text-4xl transition delay-100 ease-in-out hover:translate-x-4 hover:scale-95">
                →
              </p>
            </button>
          </div>
        </div>
      ) : null}
      {pdfViewerOpen ? <Backdrop onClick={closePdfViewer} /> : null}

      <div onClick={openPdfViewer}>
        <div className="pr-2 relative">
          <img src={pdf} className="absolute h-10 w-10 -left-10 top-2"></img>
        </div>
        <div className="flex">
          <div className="p-2 flex-initial w-40 break-words">
            <strong>{props.children.filename}</strong>
            <br />
            pages: {pdfMetadata[0].content.num_pages}
            <br />
            {String(pdfMetadata[0].content.pdf_size_mb).substring(0, 5) + " MB"}
            <br />
          </div>
          <div className="flex-grow"></div>{" "}
          {/* This will push the download button to the far right */}
          <div className="py-2 pr-2">
            <a
              href={`https://clowder.eessi.science/api/files/${props.children.id}/`}
            >
              <img src={download} className="h-8 mt-4"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfContainer;
