import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useParams, Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router";
import { useEffect, useState } from "react";

//import classes from "./Navigation.module.css";
//import css from "../Pages.module.css";
import LhumosSpaces from "../spaces.json"; //spaces whitelist database

//LOGIN
import Login from "./Login";
import Backdrop from "./Backdrop";

//ICONS
import logo from "../img/logo.png";
import compass from "../img/compass.png";
import lens from "../img/zoom-lens.png";
import user from "../img/account.png";
import moon from "../img/moon.png";

function Navbar() {
  //LOGIN
  const [loginIsOpen, setLoginIsOpen] = useState(false);

  function openLogin() {
    setLoginIsOpen(true);
  }
  function closeLogin() {
    setLoginIsOpen(false);
  }

  const location = useLocation();
  var match = matchPath({ path: "/spaces/:id" }, location.pathname);
  var SPACE_INDEX = match && match.params.id;
  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match && match.params.id;
  }

  if (SPACE_INDEX == null) {
    return (
      <header>
        {loginIsOpen ? <Login onClick={closeLogin} /> : null}
        {loginIsOpen ? <Backdrop onClick={closeLogin} /> : null}
        <nav className="sticky top-4 flex justify-center">
          <div className="flex justify-between items-center w-full max-w-screen-2xl m-auto">
            <ul className="flex mt-2">
              <li className="mr-6">
                <Link to="/" className="text-white">
                  <img src={logo} className="w-28" alt="logo" />
                </Link>
              </li>
              <li>
                <Link to="/spaces" className="flex items-center text-white">
                  <img src={compass} alt="spaces" className="w-6 h-6 mr-2" />
                  Spaces
                </Link>
              </li>
            </ul>
            
          </div>
        </nav>
      </header>
    );
  }

  return (
    <header>
      {loginIsOpen ? <Login onClick={closeLogin} /> : null}
      {loginIsOpen ? <Backdrop onClick={closeLogin} /> : null}
      <nav
        className="sticky top-0 flex justify-center md:justify-start"
        style={{ backgroundColor: LhumosSpaces[SPACE_INDEX].color1 }}
      >
        <div className="flex justify-between items-center w-full max-w-screen-2xl m-auto">
          <ul className="flex mt-2">
            <li className="mr-6">
              <Link to="/" className="text-white">
                <img src={logo} className="w-28" alt="logo" />
              </Link>
            </li>
            <li>
              <Link to="/spaces" className="flex items-center text-white">
                <img src={compass} alt="spaces" className="w-6 h-6 mr-2" />
                Spaces
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
export default Navbar;
