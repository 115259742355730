import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Breadcrumbs from "./components/Breadcrumbs";
import SharedFiles from "./components/SharedFiles";
import VideoPlayer from "react-video-js-player";
import PdfContainer from "./components/PdfContainer";
import UrlContainer from "./components/UrlContainer";
import ZipContainer from "./components/ZipContainer";
import videojs from "video.js";
import LoadingScreen from "./components/LoadingScreen";
import LhumosSpaces from "./spaces.json"; //spaces whitelist databasew
import "./Player.css";
import ApiGet from "./components/APImodules.js";
import ExtractJsonFromDescription from "./components/ExtractJsonFromDescription.js";

let FILE_ID = "";
//https://clowder.eessi.science/api/datasets/DATASET_ID/files ---> files
//then
//https://clowder.eessi.science/api/files/FILE_ID/metadata.jsonld  --->datasets
let files = [];
let dataset = [
  {
    created_at: "loading...",
    description: "this video has no description.",
    content: { previews: "loading" },
  },
];

function Player() {

  var closestSlideIndex = -1;
  //STATES
  var loading = false;
  const [isLoading, setIsLoading] = useState(true);
  const [slides, setSlides] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [style, setStyle] = useState({ display: "none" });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the threshold as needed
  const [descriptionVisible, setDescriptionVisible] = useState(!isMobile);

  //URL PARAMETERS
  let UrlParams = useParams();
  const SPACE_INDEX = UrlParams.spaceId;
  const VIDEO_NUMBER = UrlParams.videoNumber;
  const COLLECTION_ID = UrlParams.collectionId;
  const DATASET_ID = UrlParams.datasetId;

  //PLAYER STATES
  const playerRef = useRef(null);
  const player = playerRef.current;

  function jumpToTime(time) {
    if (playerRef.current) {
      playerRef.current.player.currentTime(time);
    }
  }

  function calculateClosestPassedSlideIndex(currentTime) {
    if (!slides || slides.length === 0) {
      //console.log("Slides array is empty or not defined.");
      return -1; // Return -1 if slides array is empty or no slide has been surpassed
    }

    let closestSlideIndex = -1; // Start with -1 to indicate no slide found yet
    let minTimeDifference = Number.MAX_SAFE_INTEGER; // Initialize with the maximum safe integer value

    for (let i = 0; i < slides.length; i++) {
      if (slides[i][3] !== undefined && currentTime >= slides[i][3]) {
        // Check if the timestamp exists and is less than or equal to currentTime
        const timeDifference = currentTime - slides[i][3]; // No need for Math.abs() since we want past times
        if (timeDifference < minTimeDifference) {
          closestSlideIndex = i;
          minTimeDifference = timeDifference;
        }
      }
    }

    //console.log("Closest Passed Slide Index for time", currentTime, "is:", closestSlideIndex);
    return closestSlideIndex;
  }
  useEffect(() => {
    if (!slides || slides.length === 0) {
      // If slides are empty or undefined, don't set up the interval
      return;
    }

    // Set up an interval to log the current time every second
    const intervalId = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.player.currentTime();
        //console.log("Current Time:", currentTime);

        // Calculate and update the closest slide index
        closestSlideIndex = calculateClosestPassedSlideIndex(currentTime);
        setSlideIndex(closestSlideIndex);
        //slider.current.slickGoTo(closestSlideIndex);  disabilitato in loop perchè faceva laggare
        console.log("slickGoTo: " + closestSlideIndex);
      }
    }, 1000); // Check every second

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [slides]);

  function reLoad() {
    setIsLoading(true);
  }

  const slider = useRef(null);

  //API CALLS
  useEffect(() => {
    setIsLoading(true);
    GetAllData();
    window.scrollTo(0, 0);
  }, [loading, SPACE_INDEX, COLLECTION_ID, DATASET_ID, VIDEO_NUMBER]);

  async function GetAllData() {
    files = await ApiGet(
      `https://clowder.eessi.science/api/datasets/${DATASET_ID}/files`
    );
    var file_id = files[0].id;
    for (let i = 0; i < files.length; i++) {
      if (files[i].contentType === "video/mp4") {
        file_id = files[i].id;
      }
    }

    dataset = await ApiGet(
      `https://clowder.eessi.science/api/files/${file_id}/metadata.jsonld`
    );
    setSlides(dataset[0].content.listslides);
    const playlistResult = await ApiGet(
      `https://clowder.eessi.science/api/collections/${COLLECTION_ID}/datasets/?key=test`
    );

    // Filter out datasets from the playlist where the name contains "[files]"
    const filteredPlaylistResult = playlistResult.filter(
      (dataset) => !dataset.name.includes("[files]")
    );

    const collectionData = await ApiGet(
      `https://clowder.eessi.science/api/collections/${COLLECTION_ID}`
    );

    if (collectionData) {
      const { json: playlistOrder } = ExtractJsonFromDescription(
        collectionData.description
      );
      console.log(playlistOrder);
      if (playlistOrder && Array.isArray(playlistOrder)) {
        // Ensure that the playlist order only includes datasets that have not been filtered out
        const orderedAndFilteredPlaylist = playlistOrder.filter((dataset) =>
          filteredPlaylistResult.some(
            (filteredDataset) => filteredDataset.id === dataset.id
          )
        );
        setPlaylist(orderedAndFilteredPlaylist);
      } else {
        setPlaylist(filteredPlaylistResult);
      }
    } else {
      setPlaylist(filteredPlaylistResult);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (!isLoading) {
      const jsonString = playlist[VIDEO_NUMBER].description;
      const extractedJSON = extractJSONFromString(jsonString);

      if (extractedJSON !== null) {
        const filteredSlides = extractedJSON.filter(
          (slide) => slide.isRemoved === false
        );
        setSlides(filteredSlides);
        // Do something with the filteredSlides array
      }
    }
  }, [
    isLoading,
    playlist,
    SPACE_INDEX,
    COLLECTION_ID,
    DATASET_ID,
    VIDEO_NUMBER,
  ]);

  function skipToCurrentSlide() {
    slider.current.slickGoTo(3);
  }

  function extractJSONFromString(string) {
    const regex = /\[slides\](.*?)\[endslides\]/s;
    const match = string.match(regex);
    if (match && match[1]) {
      const json = match[1].trim();
      return JSON.parse(json);
    }
    return null;
  }

  //slider carousel variables
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderSettings = {
    infinite: false,
    lazyLoad: false,
    speed: 300,
    slidesToShow: 4,
    dots: false,
    centerPadding: 1,
    centerMode: false,
    beforeChange: (current, next) => setSlideIndex(next),
  };
  // Playlist carousel variables
  const playlistSettings = {
    infinite: false,
    lazyLoad: true,
    speed: 300,
    vertical: true,
    focusOnSelect: true,
    slidesToShow: playlist.length > 4 ? 4 : playlist.length - 1, // Set maximum of 4 slides
    centerMode: false,
    centerPadding: 100,
  };
  //player variables
  const videoSource = `https://clowder.eessi.science/api/previews/${dataset[0].content.previews.mp4}#.mp4`;

  //HTML CODE
  if (isLoading) {
    return <LoadingScreen></LoadingScreen>;
  }

  const videoPlayerOptions = {
    autoplay: true,
    preload: true,
    controls: true,
    responsive: true,
    fluid: true,
  };

  const SideBar = () => (
    <div className="px-4">
      <SharedFiles collectionId={COLLECTION_ID} spaceId={SPACE_INDEX} />
      {files.length > 1 ? (
        <div
          className=" rounded-3xl px-4 pt-2 pb-4 mt-4 mb-4 "
          style={{
            backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color2}, rgba(0, 0, 0, 0))`,
          }}
        >
          <h5 className="text-white -mb-4">Attached files</h5>
          {files.map((file, idx) => (
            <div key={idx}>
              {file.contentType === "application/pdf" ? (
                <PdfContainer>{file}</PdfContainer>
              ) : (
                <div>{/* empty */}</div>
              )}
              {file.contentType === "text/url" ? (
                <UrlContainer>{file}</UrlContainer>
              ) : (
                <div>{/* empty */}</div>
              )}
              {file.contentType === "multi/files-zipped" ? (
                <ZipContainer>{file}</ZipContainer>
              ) : (
                <div>{/* empty */}</div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>{/* empty */}</div>
      )}
      <div
        className=" rounded-3xl p-4 h-auto"
        style={{
          backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color2}, rgba(0, 0, 0, 0))`,
        }}
      >
        <h5 className="text-white -mt-4">Playlist</h5>
        <Slider {...playlistSettings}>
          {playlist.map((p, idx) => (
            <Link
              key={playlist[idx].id}
              to={
                "/Player/" +
                SPACE_INDEX +
                "/" +
                idx +
                "/" +
                COLLECTION_ID +
                "/" +
                playlist[idx].id
              }
              onClick={reLoad}
              className="no-underline"
            >
              <div
                className={
                  idx == VIDEO_NUMBER
                    ? "relative rounded-xl mb-2 h-34 border-4 border-red-600 outline-none transition  delay-150 ease-in-out hover:-translate-y-1 hover:scale-95"
                    : "relative rounded-xl mb-2 h-34 transition delay-150 ease-in-out hover:-translate-y-1  hover:scale-95 "
                }
                alt={dataset.name}
                style={{
                  borderColor: LhumosSpaces[SPACE_INDEX].accent,
                }}
              >
                <h6
                  className="text-white rounded-full w-8 h-8 right-2 bottom-2 flex justify-center items-center absolute"
                  style={{
                    backgroundColor: LhumosSpaces[SPACE_INDEX].accent,
                  }}
                >
                  {idx + 1}
                </h6>
                <div
                  className="text-white text-sm rounded-lg h-32"
                  style={{
                    background: `linear-gradient(rgba(0, 0, 0, 8), rgba(0, 0, 0, 0)), url("https://clowder.eessi.science/fileThumbnail/${p.thumbnail}/blob")`,
                    backgroundSize: `cover`,
                  }}
                >
                  <div className="rounded-lg p-4">
                    <p>{p.name}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  );

  return (
    <div
      className="m-auto"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color1}, #000000)`,
      }}
    >
      <Breadcrumbs videoName={playlist[VIDEO_NUMBER].name} />
      <div className="lg:px-8">
        <div className="md:grid lg:grid grid-cols-12">
          <div className=" lg:col-span-9 md:col-span-12 sm:col-span-12">
            <div className="lg:px-4">
              <div
                className=" border-none rounded-3xl p-4"
                style={{
                  backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color2}, rgba(0, 0, 0, 0))`,
                }}
              >
                <VideoPlayer
                  options={{
                    autoplay: true,
                    controls: true,
                    responsive: true,
                    fluid: true,
                  }}
                  id="lhumos-player"
                  src={videoSource}
                  type={"video/mp4"}
                  className="rounded-2xl w-full h-[44rem] l mb-4"
                  ref={playerRef}
                />

                <div>
                  <Slider
                    {...sliderSettings}
                    ref={slider}
                    currentSlide={slideIndex}
                  >
                    {slides.map((img, idx) => (
                      <div
                        key={idx}
                        onClick={() => jumpToTime(slides[idx][3])}
                        className={
                          idx === slideIndex
                            ? "w-8 rounded-xl border-4 border-red-600 outline-none cursor-pointer transition delay-100 ease-in-out hover:-translate-y-1 hover:scale-95 relative"
                            : "w-8 rounded-xl cursor-pointer transition delay-100 ease-in-out hover:-translate-y-1 hover:scale-95 relative"
                        }
                        style={{
                          borderColor: LhumosSpaces[SPACE_INDEX].accent,
                        }}
                      >
                        <div
                          className="text-white rounded-full top-2 left-2 w-6 h-6 flex justify-center items-center p-3 absolute"
                          style={{
                            backgroundColor: LhumosSpaces[SPACE_INDEX].accent,
                          }}
                        >
                          <h6>{idx + 1}</h6>
                        </div>
                        <img
                          src={
                            "https://clowder.eessi.science/api/previews/" +
                            img[2]
                          }
                          alt={img[2]}
                          className="rounded-xl"
                        />
                      </div>
                    ))}
                  </Slider>
                  <button
                    onClick={skipToCurrentSlide}
                    className="mt-2 p-2 bg-blue-500 text-white rounded-lg"
                  >
                    Skip to Current Slide
                  </button>
                </div>
                <div className="bg-neutral-200 rounded-2xl p-2 mt-6">
                  {descriptionVisible
                    ? playlist[VIDEO_NUMBER].description.replace(
                        /\[slides\][\s\S]*?\[endslides\]/g,
                        ""
                      )
                    : playlist[VIDEO_NUMBER].description
                        .replace(/\[slides\][\s\S]*?\[endslides\]/g, "")
                        .substring(0, 100) + "..."}
                  <br></br>
                  <button
                    className="text-sm font-semibold hover:text-neutral-900 transition"
                    onClick={() => setDescriptionVisible(!descriptionVisible)}
                    style={{
                      textColor: `${LhumosSpaces[SPACE_INDEX].color1}`,
                    }}
                  >
                    {descriptionVisible ? "Show Less ↥" : "Show More ↧"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
            <SideBar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
