import React, { useEffect, useState } from 'react';
import ApiGet from './APImodules.js'; // Ensure this path is correct

import PdfContainer from "./PdfContainer";
import UrlContainer from "./UrlContainer";
import ZipContainer from "./ZipContainer";
import LhumosSpaces from "../spaces.json"; //spaces whitelist databasew

function SharedFiles({ collectionId, spaceId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataset = async () => {
      try {
        const datasets = await ApiGet(
          `https://clowder.eessi.science/api/collections/${collectionId}/datasets/?key=test`
        );
        const filesDataset = datasets.find(dataset => dataset.name.includes("[files]"));

        if (!filesDataset) {
          setError("No shared files dataset found.");
          setIsLoading(false);
          return;
        }

        // Once the dataset is found, fetch the files within it
        fetchFilesInDataset(filesDataset.id);
      } catch (err) {
        setError("Failed to fetch datasets.");
        console.error(err);
        setIsLoading(false);
      }
    };

    fetchDataset();
  }, [collectionId]);

  const fetchFilesInDataset = async (datasetId) => {
    try {
      const filesInDataset = await ApiGet(
        `https://clowder.eessi.science/api/datasets/${datasetId}/files`
      );
      if (filesInDataset && filesInDataset.length > 0) {
        setFiles(filesInDataset);
        console.log("Files in shared dataset:", filesInDataset); // Debug log the found files
      } else {
        setError("No files found in the shared files dataset.");
      }
    } catch (err) {
      setError("error fetching");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div>
      {files.length >= 1 ? (
        <div
          className=" rounded-3xl px-4 pt-2 pb-4 "
          style={{
            backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[spaceId].color2}, rgba(0, 0, 0, 0))`,
          }}
        ><h5 className='text-white -mb-4'>Global files</h5>
          {files.map((file, idx) => (
            <div key={idx}>
              {file.contentType === "application/pdf" ? (
                <PdfContainer>{file}</PdfContainer>
              ) : (
                <div>{/* empty */}</div>
              )}
              {file.contentType === "text/url" ? (
                <UrlContainer>{file}</UrlContainer>
              ) : (
                <div>{/* empty */}</div>
              )}
              {file.contentType === "multi/files-zipped" ? (
                <ZipContainer>{file}</ZipContainer>
              ) : (
                <div>{/* empty */}</div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>{/* empty */}</div>
      )}
    </div>
  );
}

export default SharedFiles;
