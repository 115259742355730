import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react";
import Footer from "./components/Footer";

import Cecamlogo from "./img/cecamlogo.png";
import Marvellogo from "./img/marvellogo.png";
import Maxlogo from "./img/maxlogo.png";
import DomeLogo from "./img/domelogo.png";
import MultiLogo from "./img/multilogo.png";
import atoms from "./img/atoms.png";
import atomPlanet from "./img/atom_planet.png";
import bg from "./img/bg.webp";
import wp from "./img/wp.jpg";
import LhumosLogo from "./img/logo_icon_transparent.png";
import playicon from "./img/playicon.png";
import compass from "./img/compass.png";

toast.info(
  "Welcome to the alpha version of the Lhumos training portal! Please note that this website is still in development and may contain errors or inaccuracies."
);

function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black relative overflow-hidden">
      <ToastContainer />

      <div className="relative bg-cover bg-bottom bg-fixed bg-no-repeat py-12 sm:py-24 text-white">
        <img
          src={atomPlanet}
          alt="Atom Planet"
          className="absolute w-2/3 top-0 -right-40 opacity-25 rotating"
          style={{
            animation: "rotation 120s infinite linear",
            transformOrigin: "center center",
          }}
        />

        <style>
          {`
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  `}
        </style>
        <div className="max-w-screen-xl mx-auto px-6 sm:px-8">
          <div className="sm:grid sm:grid-cols-3">
            <div className="sm:col-span-2 relative z-10">
              <h1 className="text-4xl font-semibold leading-tight">
                Learning Hub For Modelling and Simulation
              </h1>
              <p className="text-lg mt-4">
                A growing repository of domain specific training material in
                simulation and modeling. Made by researches for researchers, it
                features video recordings of lectures, slide navigation, and
                access to support material, code repositories, and exercises.
              </p>
              <div className="mt-8 flex items-center">
                <Link
                  to="/spaces"
                  className="flex items-center px-6 py-3 rounded-lg bg-[#4883b8] text-white text-lg font-semibold hover:bg-sky-600 transition hover:scale-105 no-underline mr-4" // Added no-underline to remove text decoration
                >
                  <img
                    src={compass}
                    alt="browse spaces"
                    className="w-8 h-8 mr-2" // Keep the margin to space the icon and text
                  />
                  Browse spaces
                </Link>
                <Link
                  to="https://alpha.lhumos.org/player/6/0/65a53f9de4b08f2db4344741/65b2180ae4b08f2db4352ab7"
                  className="flex items-center px-6 py-3 rounded-lg bg-[#4883b8] text-white text-lg font-semibold hover:bg-sky-600 transition hover:scale-105 no-underline" // Added no-underline to remove text decoration
                >
                  <img
                    src={playicon}
                    alt="Get to Know Lhumos"
                    className="w-8 h-8 mr-2" // Keep the margin to space the icon and text
                  />
                  Get to Know Lhumos!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-screen-2xl m-auto  text-white-900 py-8 sm:py-12 px-6 sm:px-8 mt-8 rounded-2xl relative">
        <div className="text-white">
          <h2 className="text-2xl font-semibold mb-2">Welcome to Lhumos!</h2>
          <p>
            Please note that this website is still in development. You can help
            by pointing out problems and suggesting improvements at
            Lhumos@cecam.org
          </p>
          <p>Thank you for your interest!</p>
        </div>
      </div>
      <div className="max-w-screen-2xl m-auto p-4">
        <div className="bg-gradient-to-b from-sky via-sky-900 to-sky-black text-white py-12 px-6 sm:px-8 mt-8 rounded-2xl relative">
          <h1 className="text-3xl font-bold mb-4">Funded by</h1>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
            <SpaceCard
              to="/spaces/0"
              logo={Cecamlogo}
              title="CECAM"
              description="Fostering computational science for societal progress"
            />
            <SpaceCard
              to="/spaces/1"
              logo={Marvellogo}
              title="MARVEL"
              description="Design and discovery of novel materials"
            />
            <SpaceCard
              to="/spaces/2"
              logo={Maxlogo}
              title="MᴀX"
              description="Enabling frontier HPC in the materials domain"
            />
            <SpaceCard
              to="/spaces/3"
              logo={MultiLogo}
              title="MultiXscale"
              description="EuroHPC JU Centre of Excellence in multiscale modelling"
            />
            <SpaceCard
              to="/spaces/4"
              logo={DomeLogo}
              title="DOME"
              description="An industrial data marketplace ecosystem based on Open Science and Open Innovation"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function SpaceCard({ to, logo, title, description }) {
  return (
    <Link
      to={to}
      className="block rounded-lg p-4 text-center no-underline text-white"
    >
      <img
        src={logo}
        alt={`${title} logo`}
        className="w-40 h-40 mx-auto mb-4 rounded-full"
      />
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-sm text-center">{description}</p>
    </Link>
  );
}

export default Home;
