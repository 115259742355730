import React from "react";
import "animate.css";
import { matchPath, useLocation } from "react-router";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import classes from "./Navigation.module.css";
import lcss from "./Login.module.css";
import LhumosSpaces from "../spaces.json"; //spaces whitelist database

//ICONS
import GitLabLogo from "../img/gitlab.png";
import closeIcon from "../img/cross.png";

function Login(props) {
  function closeLogin() {
    props.onClick();
  }

  const location = useLocation();
  var match = matchPath({ path: "/spaces/:id" }, location.pathname);
  var SPACE_INDEX = match && match.params.id;
  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match && match.params.id;
  }
  //HTML CODE
  return (
    <div
      className={lcss.card}
      style={{ backgroundColor: LhumosSpaces[SPACE_INDEX].color1 }}
    >
      <div className="animate__animated animate__fadeIn">
        <img
          src={closeIcon}
          onClick={closeLogin}
          className={classes.buttonicon}
          style={{ display: "inline-block" , marginRight: "22em"}}
        ></img><h2 style={{ display: "inline-block" }}>Sign in</h2>
        <div className="Auth-form-container">
          <form className="Auth-form">
            <div className="Auth-form-content">
              <div className="form-group mt-3">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                />
              </div>
              <div className="d-grid gap-2 mt-3">
                <button
                  type="submit"
                  className="btn"
                  style={{
                    color: "white",
                    backgroundColor: LhumosSpaces[SPACE_INDEX].color2,
                  }}
                >
                  Submit
                </button>
              </div>
              <p className="forgot-password text-right mt-2">
                Forgot <a href="#">password?</a>
              </p>
            </div>
          </form>
          <a href="https://clowder.eessi.science/authenticate/gitlab">
            <img
              src={GitLabLogo}
              alt="git lab login"
              className={lcss.loginLogo}
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
